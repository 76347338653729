<template>
  <v-row class="ma-0">
    <v-col>
      <v-card max-width="580" class="mx-auto text-center px-16 py-13">
        <v-img class="mx-auto" max-width="162" src="@/assets/img/error.svg"></v-img>
        <div class="text-h5 font-weight-bold mt-10">Your account is blocked</div>
        <p class="text-center opasity--text mt-3">
          <v-row>
            <v-col cols="8" class="mx-auto">To unblock and return to account contact the support team.</v-col>
          </v-row>
        </p>
        <div>
          <router-link class="input--text" to="/login">
            <v-icon class="input--text" left>mdi-comment mdi-flip-h</v-icon> Support team
          </router-link>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
};
</script>
